module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AOP Offizielle Website","short_name":"aop","start_url":"/","background_color":"#301c3d","theme_color":"#301c3d","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"afb9c9bf9053aa29f33fb6e3c3e416d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-29544828-1","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"290855841536370"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
